<template>
  <mynav navIndex="2"></mynav>
  <div class="main">
    <h3>{{title}}</h3>
    <div class="div-x-place">
       <a href="/etf/gd.html" rel="noopener noreferrer" class="title" :class="{'title-strong': dtype==0}" >
        黄金ETF
        <div :class="{lineTitle: dtype == 0}"></div>
      </a>
      <!-- <div class="title" @click="titleClick" :class="{activeTitle: dtype == 1}" :data-dtype="1"> -->
      <a href="/etf/si.html" rel="noopener noreferrer" class="title" :class="{'title-strong': dtype==1}">
        白银ETF
        <div :class="{lineTitle: dtype == 1}"></div>
      </a>
    </div>
    <br>
    <div id = "kline">
    </div>
    <span class="tooltip-note">图上停留2秒,可使用滑动十字标</span>
    <h3><strong>{{dname}}近期ETF数据</strong></h3>
    <table class="table">
    <thead>
    <tr>
    <th>日期</th>
    <th>持仓量(吨)</th>
    <th>持仓变动(吨)</th>
    </tr>
    </thead>
    <tbody>
      <tr v-for="(data, index) in newestdatas" v-bind:key="index">
      <td>{{data.publish_time}}</td>
      <td>{{data.weight}}</td>
      <td v-html="data.c"></td>
      </tr>
    </tbody>
    </table>
  </div>
</template>

<script>
import {dispose, init} from 'klinecharts'
import axios from "axios";
import datos from "../apps.js"
export default {
  name: 'Etf',
  data() {
    return {
      hasOptionChart: false,
      dtype: {'gd': 0, 'si': 1}[this.$route.params.type],
      chart: null,
      page: 0,
      hasMore: true,
      title: "SPDR Gold Trust",
      dname: "黄金",
      limit: 0,
      newestdatas: [],
      num: 0
    }
  },
  watch: {
    dtype(){
      this.changeData()
    }
  },
  mounted() {
    console.log(this.$route.params);
    this.num = Math.floor(Math.random()*40)
    this.initChart()
    this.getData(false)
  },
  beforeUnmount() {
    //组件销毁前先销毁 ECharts 实例
    if (!this.chart) { return }
    dispose('kline')
  },
  methods: {
    titleClick(e){
      let {
        dtype
      } = e.target.dataset
      this.dtype = dtype
    },
    initChart(){
      if (this.chart) {
        dispose('kline')
      }
      this.chart = init('kline')
      this.chart.loadMore(timestamp => {
        console.log("timestamp:"+ timestamp);
        setTimeout(() => {
          this.getMoreData()
        }, 300)
      })
    },
    changeData(){
      
        if (this.dtype == 1) {
            this.title = "iShares Silver Trust"
            this.dname = "白银"
        }else{
            this.title = "SPDR Gold Trust"
            this.dname = "黄金"
        }
        this.hasOptionChart = false
        this.hasMore = true
        this.page = 0
        this.initChart()
        this.getData(false)
    },
    getMoreData(){
      this.getData(true)
    },
    // uuid() {
    //     var s = [];
    //     var hexDigits = "0123456789abcdef";
    //     for (var i = 0; i < 36; i++) {
    //         s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    //     }
    //     s[14] = "4";  // bits 12-15 of the time_hi_and_version field to 0010
    //     s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);  // bits 6-7 of the clock_seq_hi_and_reserved to 01
    //     s[8] = s[13] = s[18] = s[23] = "-";
    //     var uuid = s.join("");
    //     return uuid;
    // },
    getData(addmore){
      if (this.dtype == 1) {
            this.title = "iShares Silver Trust"
            this.dname = "白银"
        }else{
            this.title = "SPDR Gold Trust"
            this.dname = "黄金"
        }

      if (!this.hasMore) {
        return
      }
      axios
      .get("/" + datos()[this.num].desc + "/v1/etf/data/list", {params: {
          dtype: this.dtype, page: this.page}})
      .then((data) => {
        if (data.data.code == 200) {
          let priceData = []
          for (let index = 0; index <data.data.data.length-1; index++) {
            let elem = data.data.data[index]
            let d = elem.weight/1000
            let dPre = data.data.data[index+1].weight/1000
            priceData.push({
              close: d, open: dPre,
              high: d, low: d,
              timestamp: elem.timestamp*1000})
          }
          priceData = priceData.reverse()

          if (this.page == 0) {
            this.newestdatas = []
            for (let index = 0; index < 3; index++) {
              let elem = data.data.data[index]
              let c = (elem.weight - data.data.data[index+1].weight) / 1000
              c = c.toFixed(2)
              if(c >0){
                c="<strong style='color:#EF5350'>" + c +"</strong>"
              }else if(c < 0){
                c="<strong style='color:#26A69A'>" + c + "</strong>"
              }else{
                c="<strong style='color:#888888'>" + c + "</strong>"
              }
              this.newestdatas.push({
                publish_time: elem.publish_time,
                weight: elem.weight/1000,
                c: c})
            }
          }

          this.page += 1
          if (priceData.length > this.limit) {
            this.limit = priceData.length
          }
          if (this.limit > priceData.length || priceData.length == 0 || this.page >= 3) {
            this.hasMore = false
          }

          if (!this.hasOptionChart) {
            this.optionChart()
          }
          if (!addmore) {
            this.chart.applyNewData(priceData, this.hasMore)
          }else{            
            this.chart.applyMoreData(priceData, this.hasMore)
          }
        }
      })
      .catch((err) => {
        console.log(err);
      })
    },
    optionChart(){
      this.hasOptionChart = true
      this.chart.setStyleOptions({
        // grid:{
        //   show: false,
        // },
        candle: {
            priceMark: {
                last: {
                    show: false
                }
            },
            type: 'area',
            margin: {
                top: 0.1,
                bottom: 0.05
            },
          tooltip: {
            showRule: 'follow_cross',
            labels: ['持仓 ', '变动'],
            showType: 'rect',
            values: kLineData => {
                const change = kLineData.close - kLineData.open
              return [
                { value: `${kLineData.open.toFixed(2)} 吨` },
                {
                  value: `${change.toFixed(2)} 吨`,
                  color: change > 0 ? '#EF5350' : '#26A69A'
                }
              ]
            }
          },
        },
        yAxis:{
          show: false,
          inside: true,
        },
      })
      this.chart.setOffsetRightSpace(2)
      if (document.body.clientWidth < 768) {
        this.chart.setDataSpace(8)
        this.chart.setRightMinVisibleBarCount(20)
        this.chart.setLeftMinVisibleBarCount(30)
      }else if(document.body.clientWidth < 1100){
        this.chart.setDataSpace(10)
        this.chart.setRightMinVisibleBarCount(30)
        this.chart.setLeftMinVisibleBarCount(60)
      }else{
        this.chart.setDataSpace(10)
        this.chart.setRightMinVisibleBarCount(80)
        this.chart.setLeftMinVisibleBarCount(90)
      }
    }
  }
}
</script>
<style>
  @import '../css/main.css';
  a{
    cursor: pointer;
    text-decoration: none;
  }
  .title {
    font-size: 1.1rem;
    text-align: center;
    width: 50%;
    color: #2c3e50;
  }
  .title-strong{
    font-weight: 600;
  }
</style>